import { Box, Collapse, Heading, useDisclosure } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";
import { PrescriptionEvent } from "features/staffCommons/components/JournalRecords/api/types";

import PrescriptionSection from "../PrescriptionDetailsSection";

type PrescriptionDetailsProps = {
  prescriptionEvent: PrescriptionEvent;
};

const PrescriptionDetails: FunctionComponent<PrescriptionDetailsProps> = ({
  prescriptionEvent,
}) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      w="full"
      py="2"
      px="7"
      border={
        isOpen
          ? "1px solid rgba(88, 154, 175, 0.7)"
          : "1px solid rgba(0, 0, 0, 0.2)"
      }
      borderRadius="0.5rem"
      boxShadow={isOpen ? "0 4px 12px rgba(0, 0, 0, 0.15)" : "none"}
      onClick={onToggle}
      cursor="pointer"
      overflow="hidden"
      minW="max-content"
      position="relative"
    >
      <Heading
        fontSize="1rem"
        fontWeight={isOpen ? 700 : 300}
        fontFamily="main"
      >
        {t("View prescription pills")}
      </Heading>
      <Collapse in={isOpen} animateOpacity>
        {Object.keys(prescriptionEvent.pills.apl).length > 0 && (
          <PrescriptionSection
            title="APL"
            pillsInfo={prescriptionEvent.pills.apl}
            eventID={prescriptionEvent.id}
          />
        )}
        {Object.keys(prescriptionEvent.pills.regular).length > 0 && (
          <PrescriptionSection
            title="Regular"
            pillsInfo={prescriptionEvent.pills.regular}
            eventID={prescriptionEvent.id}
          />
        )}
        {Object.keys(prescriptionEvent.pills.regenboogen).length > 0 && (
          <PrescriptionSection
            title="Regenboogen"
            pillsInfo={prescriptionEvent.pills.regenboogen}
            eventID={prescriptionEvent.id}
          />
        )}
      </Collapse>
    </Box>
  );
};

export default PrescriptionDetails;
