export const serviceColors: { [key: string]: string } = {
  DOCTOR_MEETING: "rgba(67, 179, 186, 0.2)",
  NURSE_MEETING: "pink.100",
};

export const textColor: { [key: string]: string } = {
  DOCTOR_MEETING: "rgba(67, 179, 186, 1)",
  NURSE_MEETING: "pink.400",
};

export const serviceNames: { [key: string]: string } = {
  DOCTOR_MEETING: "Doctor meeting",
  NURSE_MEETING: "Nurse meeting",
};

export const roleNames: { [key: string]: string } = {
  doctor: "doctor",
  nurse: "nurse",
};
